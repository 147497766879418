.container {
  height: 100%;
  padding: 15px;
}

.title {
  text-align: start;
}

.itemsContainer {
  margin-top: 15px;

  display: flex;
  flex-direction: column;
}

.itemContainer {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  text-align: start;
}

.itemContainer:not(:first-child) {
  margin-top: 15px;
}

.itemPriceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemQuantityContainer {
  max-width: 100px;
  width: 100%;
  text-align: center;

  display: flex;
}
